<template>
    <div class="referral d-flex-r-w-jfs-ast-g1-s1-gap10">
        <h2 class="p-30">کیف پول ها</h2>
        <p class="px-30 pb-20">
            با ارسال لینک یا کد دعوت به دوستان خود به هر فردی که با استفاده از کد دعوت و یا لینک دعوت شما ثبت نام نماید
            20% درصد از کارمزد ریالی که زرکوین از هر معامله آن فرد دریافت می کند به شما تعلق می گیرد. هم چنین فرد دعوت
            شده می تواند دو معامله با کارمزد صفر انجام دهد و دو پله هم کارمزد معاملاتی شخص کاهش پیدا می کند.
        </p>
        <referral-box class="basis-300" header="کد" icon="ReferralCode" :link="link" :shareLinks="codeLinks"/>
        <referral-box class="basis-400" header="لینک" icon="ReferralLink"
                      :link="refLink + link" :shareLinks="linkLinks"/>
        <referral-statistics class="basis-150"/>
    </div>
</template>

<script>
    import ReferralBox from "@/components/Panel/Referral/ReferralBox";
    import ReferralStatistics from "@/components/Panel/Referral/ReferralStatistics";

    export default {
        name: "Referral",
        components: {ReferralStatistics, ReferralBox},
        data() {
            return {
                link: 123456,
                refLink: 'https://zarcoin.ir/register?referral=',
                codeLinks: [
                    {
                        name: 'Email',
                        link: `mailto:?subject=کد دعوت به سایت ارزکوین&body=${this.link}`,
                        icon: 'share-email'
                    },
                    {
                        name: 'Whatsapp',
                        link: `whatsapp://send?text=کد دعوت شما به ارزکوین : ${this.link}`,
                        icon: 'share-whatsapp'
                    },
                    {
                        name: 'Telegram',
                        link: `https://t.me/share/url?url=https://zarcoin.ir&text=کد دعوت شما به ارزکوین : ${this.link}`,
                        icon: 'share-telegram'
                    },
                ],
                linkLinks: [
                    {
                        name: 'LinkedIn',
                        link: `https://www.linkedin.com/sharing/share-offsite/?url=${this.refLink + this.link}`,
                        icon: 'share-linkedin'
                    },
                    {
                        name: 'Twitter',
                        link: `https://twitter.com/intent/tweet?url=${this.refLink + this.link}&text=ورود به دنیای ارز های دیجیتال`,
                        icon: 'share-twitter'
                    },
                    {
                        name: 'Facebook',
                        link: `https://www.facebook.com/sharer.php?u=${this.refLink + this.link}`,
                        icon: 'share-facebook'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .referral {
        padding: 0 20px 20px;
        text-align: right;
        align-content: flex-start;
        /*height: 100%;*/
    }
</style>