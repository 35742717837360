<template>
    <div class="referral-box l-radius py-20 px-10 d-flex-c-jsb-ast-gap15">
        <h3 v-if="header" class="label d-flex-ac-jfs-gap10">
            <inline-svg v-if="icon" :src="require('@/assets/Icons/Referral/'+ icon + '.svg')"
                        class="icon gradient-template-gold-trans-linear back xs-radius"/>
            {{header}} دعوت
        </h3>
        <div class="link-input d-flex-jsb-ac s-radius">
            <button class="d-flex-r-jc-ac-gap10 px-15 s-radius" type="button" @click="copy(link)">
                <inline-svg width="16" :src="require('@/assets/Icons/copy.svg')"/>
                کپی
            </button>
            {{link}}
        </div>
        <div class="share-links d-flex-r-w-jfs-afs-gap10">
            <p class="fullWidth m-font">اشتراک گذاری {{header}} از طریق</p>
            <a class="m-font d-flex-r-jc-ac-gap10 px-10" v-for="shareLink in shareLinks" :key="shareLink.name" :href="shareLink.link">
                <inline-svg :src="require('@/assets/Icons/Referral/'+ shareLink.icon + '.svg')"/>
                {{shareLink.name}}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReferralBox",
        props: ['header', 'icon', 'link', 'shareLinks'],
        methods: {
            copy(link) {
                navigator.clipboard.writeText(link)
                alert('کپی شد')
            }
        }
    }
</script>

<style lang="scss">
    .referral-box {
        background-color: var(--dark);
        .label {
            .icon {
                padding: 4px;
                height: 28px;
                width: 28px;
            }
        }
        .link-input{
            background-color: var(--side-item);
            height: 50px;
            padding-left: 15px;
            text-align: left;
            button{
                background-color: var(--yellow3);
                height: 100%;
                color: var(--white);
                cursor: pointer;
            }
        }
        .share-links{
            a{
                background-color: var(--side-item);
                border-radius: 18px;
                height: 35px;
            }
        }
    }
</style>