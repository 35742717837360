<template>
    <div class="referral-statistics d-flex-c-jsb-ast l-radius px-10 py-20">
        <h3 class="label d-flex-ac-jfs-gap10">
            <inline-svg :src="require('@/assets/Icons/Referral/ReferralStatistics.svg')"
                        class="icon gradient-template-gold-trans-linear back xs-radius"/>
            آمار دوستان دعوت شده
        </h3>
        <div class="item d-flex-r-ac-jsb-ac">
            <span class="d-flex-r-ac-jfs-gap10">
                <inline-svg width="40" :src="require('@/assets/Icons/Referral/friends.svg')"/>
                تعداد دوستان
            </span>
            0
        </div>
        <div class="item d-flex-r-ac-jsb-ac">
            <span class="d-flex-r-ac-jfs-gap10">
                <inline-svg width="40" :src="require('@/assets/Icons/Referral/profit.svg')"/>
                سود شما
            </span>
            0
        </div>
    </div>
</template>

<script>
    export default {
        name: "ReferralStatistics"
    }
</script>

<style lang="scss">
    .referral-statistics {
        background-color: var(--dark);
        .label {
            .icon {
                padding: 4px;
                height: 28px;
                width: 28px;
            }
        }
    }
</style>